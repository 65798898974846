<template>
  <v-container>
    <!-- Header -->
    <header class="custom-header mb-6">
      <h4>පැකේජ විස්තර</h4>
    </header>

    <v-data-table
      :footer-props="$store.state.dataTableFooterProps"
      :headers="headers"
      :items="packages"
      :loading="loading"
      class="custom-shadow custom-radius"
      hide-default-footer
      show-expand
    >
      <template v-slot:top>
        <v-toolbar class="custom-radius" color="#fafafa" dense flat>
          <v-toolbar-title class="subtitle-1">මාර්ගගත පැකේජ</v-toolbar-title>
        </v-toolbar>
      </template>
      <template slot="no-data">ලබා ගත හැකි දත්ත නැත</template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <p :key="feature.featureId" class="my-1" v-for="feature in item.package_features">
            <v-icon>{{feature.limit ? 'mdi-check' : 'mdi-close'}}</v-icon>
            {{feature.feature.name}}
            <span
              class="roboto pa-1"
              style="background-color: #1E88E5; border-radius: 50px; color: white"
              v-if="feature.limit > 1"
            >{{feature.limit}}</span>
          </p>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-btn @click="openPackageUpdateDialog(item)" color="secondary" icon>
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-switch
            @change="activate(item)"
            class="mt-1 ml-2"
            color="accent"
            hide-details
            v-model="item.active"
          ></v-switch>
        </div>
      </template>
    </v-data-table>

    <v-data-table
      :footer-props="$store.state.dataTableFooterProps"
      :headers="manualPkgTableheaders"
      :items="manualPackages"
      :loading="loadingManualPkgTable"
      class="custom-shadow custom-radius mt-10"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar class="custom-radius" color="#fafafa" dense flat>
          <v-toolbar-title class="subtitle-1">වෙනත් පැකේජ</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog max-width="390" persistent v-model="manualPkgDialog">
            <template v-slot:activator="{ on }">
              <v-btn color="accent" depressed fab small v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title>නව පැකේජයක් එක් කරන්න</v-card-title>
              <v-card-text class="py-3">
                <v-row>
                  <v-col cols="12">
                    <v-text-field dense label="මිල" type="number" v-model="manualPackage.price"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      label="කාල සීමාව (මාස)"
                      type="number"
                      v-model="manualPackage.periodInMonths"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :items="[{label: 'ඇත', value: 1}, {label: 'නැත', value: 0}]"
                      dense
                      item-text="label"
                      item-value="value"
                      label="දුරකතන අංකය"
                      v-model="manualPackage.phoneNumber"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :items="[{label: 'ඇත', value: 1}, {label: 'නැත', value: 0}]"
                      dense
                      item-text="label"
                      item-value="value"
                      label="ඡායාරූපය"
                      v-model="manualPackage.photo"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="closeManualPackageDialog" color="error" text>අවලංගු කරන්න</v-btn>
                <v-btn @click="saveManualPackage" color="success" text>සුරකින්න</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template slot="no-data">ලබා ගත හැකි දත්ත නැත</template>
      <template v-slot:item.phoneNumber="{ item }">
        <v-icon>{{item.phoneNumber ? 'mdi-check': 'mdi-close'}}</v-icon>
      </template>
      <template v-slot:item.photo="{ item }">
        <v-icon>{{item.photo ? 'mdi-check': 'mdi-close'}}</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-btn @click="editManualPackage(item)" class="mr-2" color="secondary" icon>
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
          <v-btn @click="deleteManualPackage(item)" color="error" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog max-width="390" persistent v-model="dialog">
      <v-card>
        <v-card-title>සංස්කරණය කරන්න</v-card-title>
        <v-card-text class="py-3">
          <v-text-field
            dense
            label="කාල සීමාව (මාස)"
            type="number"
            v-model="updateForm.periodInMonths"
          ></v-text-field>
          <v-text-field
            dense
            label="දුරකතන අංක"
            type="number"
            v-model="updateForm.phoneNumbersLimit"
          ></v-text-field>
          <v-text-field dense label="මිල" type="number" v-model="updateForm.price"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" color="error" text>අවලංගු කරන්න</v-btn>
          <v-btn @click="updatePackage" color="success" text>යාවත්කාලීන කරන්න</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { ApiService } from "../../services/api.service";
import { mapMutations } from "vuex";
import { SHOW_ALERT, CHANGE_LOADING_STATE } from "../../store/mutation-types";
import ConfirmDialog from "../../components/ConfirmDialog";

export default {
  components: {
    ConfirmDialog
  },

  data: () => ({
    headers: [
      { text: 'නම', sortable: false, value: 'name' },
      { text: 'කාල සීමාව (මාස)', sortable: false, value: 'periodInMonths' },
      { text: 'මිල (රුපියල්)', value: 'price', sortable: false },
      { text: 'ක්‍රියා', value: 'actions', sortable: false, align: 'end' },
    ],
    manualPkgTableheaders: [
      { text: 'මිල (රුපියල්)', sortable: false, value: 'price' },
      { text: 'කාල සීමාව (මාස)', sortable: false, value: 'periodInMonths' },
      { text: 'දුරකතන අංකය', value: 'phoneNumber', sortable: false },
      { text: 'ඡායාරූපය', value: 'photo', sortable: false },
      { text: 'ක්‍රියා', value: 'actions', sortable: false, align: 'end' },
    ],
    packages: [],
    manualPackages: [],
    loading: false,
    loadingManualPkgTable: false,
    dialog: false,
    manualPkgDialog: false,
    selectedPackage: null,
    updateForm: {
      periodInMonths: '',
      phoneNumbersLimit: '',
      price: ''
    },
    manualPackage: {
      price: '',
      periodInMonths: '',
      phoneNumber: {},
      photo: {}
    },
    defaultManualPackage: {
      price: '',
      periodInMonths: '',
      phoneNumber: {},
      photo: {}
    },
    editedManualPackageIndex: -1
  }),

  async mounted() {
    this.$store.commit(CHANGE_LOADING_STATE, { active: true });
    this.fetchOnlinePackages();
    await this.fetchManualPackages();
    this.$store.commit(CHANGE_LOADING_STATE, { active: false });
  },

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),

    // Fetch online packages
    fetchOnlinePackages: async function () {
      this.loading = true;
      try {
        const url = 'packages';
        const response = await ApiService.get(url);
        this.packages = response.data;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    // Fetch manual packages
    fetchManualPackages: async function () {
      this.loadingManualPkgTable = true;
      try {
        const url = 'manual-packages';
        const response = await ApiService.get(url);
        this.manualPackages = response.data;
      } catch (error) {
        console.log(error);
      }
      this.loadingManualPkgTable = false;
    },

    // Activate or Deactivate an online package
    activate: async function (pkg) {
      const oldStatus = !pkg.active;
      const messageText = pkg.active ? "සක්‍රිය" : "අක්‍රිය";

      if (await this.$refs.confirm.open(`${messageText} කරන්න`, 'ඔබට විශ්වාසද ?', { color: 'success', persistent: true })) {
        this.loading = true;
        try {
          const url = "packages/activate";
          const data = { id: pkg.id, value: pkg.active };
          const response = await ApiService.put(url, data);
          this.showAlert({ message: `පැක‌ේජය ${messageText} කරන ලදි`, type: "info" });
        } catch (error) {
          pkg.active = oldStatus;
        }
        this.loading = false;
      } else {
        pkg.active = oldStatus;
      }
    },

    // Open package update dialog
    openPackageUpdateDialog: function (pkg) {
      this.selectedPackage = pkg;
      this.updateForm = {
        periodInMonths: pkg.periodInMonths,
        phoneNumbersLimit: pkg.package_features[1].limit,
        price: pkg.price
      };
      this.dialog = true;
    },

    // Update package details
    updatePackage: async function () {
      this.dialog = false;
      this.loading = true;
      try {
        const url = `packages/update/${this.selectedPackage.id}`;
        const data = { data: this.updateForm }
        const response = await ApiService.put(url, data);
        this.selectedPackage.periodInMonths = response.data.periodInMonths;
        this.selectedPackage.package_features = response.data.package_features;
        this.selectedPackage.price = response.data.price;
        this.showAlert({ message: "පැක‌ේජය යාවත්කාලීන කරන ලදි", type: "success" });
      } catch (error) {
        this.showAlert({ message: "යාවත්කාලීන කිරීම අසාර්ථකයි", type: "error" });
      }
      this.loading = false;
    },

    // Save or update a manual package
    saveManualPackage: async function () {
      this.manualPkgDialog = false;
      this.loadingManualPkgTable = true;
      try {
        if (this.editedManualPackageIndex > -1) { // Edit
          const url = `manual-packages/${this.manualPackage.id}`;
          const response = await ApiService.put(url, this.manualPackage);
          Object.assign(this.manualPackages[this.editedManualPackageIndex], response.data);
          this.showAlert({ message: "පැක‌ේජය යාවත්කාලීන කරන ලදි", type: "success" });
        } else { // Create
          const url = 'manual-packages';
          const response = await ApiService.post(url, this.manualPackage);
          this.manualPackages.push(response.data);
          this.showAlert({ message: "නව පැක‌ේජය එකතුඑකතු කරන ලදි", type: "success" });
        }
      } catch (error) {
        console.log(error);
      }
      this.loadingManualPkgTable = false;
    },

    // Edit a manual package
    editManualPackage: function (pkg) {
      this.editedManualPackageIndex = this.manualPackages.indexOf(pkg);
      this.manualPackage = Object.assign({}, pkg);
      this.manualPkgDialog = true;
    },

    // Close manual package dialog
    closeManualPackageDialog: function () {
      this.manualPkgDialog = false
      this.$nextTick(() => {
        this.manualPackage = Object.assign({}, this.defaultManualPackage)
        this.editedManualPackageIndex = -1
      })
    },

    // Delete a manual package
    deleteManualPackage: async function (pkg) {
      if (await this.$refs.confirm.open('පැක‌ේජය ඉවත් කරන්න', 'පැක‌ේජය ඉවත් කිරීමට අවශ්‍යද?', { color: 'warning', persistent: true })) {
        this.loadingManualPkgTable = true;
        try {
          const url = `manual-packages/${pkg.id}`;
          const response = await ApiService.delete(url);
          const index = this.manualPackages.indexOf(pkg);
          this.manualPackages.splice(index, 1);
          this.showAlert({ message: "පැක‌ේජය ඉවත් කරන ලදි", type: "success" });
        } catch (error) {
          if (error.response.data.code == 23000) {
            this.showAlert({ message: "මෙම පැක‌ේජය ඉවත් කිරීමට න‌ොහැක. පැක‌ේජය භාවිතා කරන සාමාජිකයන් ඇත.", type: "error" });
          }
        }
        this.loadingManualPkgTable = false;
      }
    }
  }
}
</script>